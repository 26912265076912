var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.sites.data,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":_vm.totalItems,"options":_vm.options,"items-per-page":50,"footer-props":{
                    itemsPerPageOptions: [10, 15, 25, 50],
                },"item-key":"ip","loading":_vm.loading},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},_vm.sortByUpdate],"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.sortDescUpdate],"update:options":function($event){_vm.options=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
                var group = ref.group;
                var isOpen = ref.isOpen;
                var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":"8"}},[_c('v-btn',{attrs:{"icon":"","aria-label":"Minimize/Maximize group"},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-server")]),_vm._v(" "+_vm._s(group)+" ")],1)]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [(item.status === 0)?_c('v-badge',{staticClass:"pulse",attrs:{"color":"grey","inline":"","dot":""}}):_vm._e(),(item.status === 1)?_c('v-badge',{attrs:{"color":"green","inline":"","dot":""}}):_vm._e(),(item.status === 2)?_c('v-badge',{attrs:{"color":"red","inline":"","dot":""}}):_vm._e(),(item.status === 3)?_c('v-badge',{attrs:{"color":"orange","inline":"","dot":""}}):_vm._e()]}},{key:"item.website",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.website)),_c('br'),_c('small',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(item.user)+" "),(item.php_version !== null)?[_c('v-icon',{staticClass:"ml-3",attrs:{"small":""}},[_vm._v("mdi-language-php")]),_vm._v(" "+_vm._s(item.php_version)+" ")]:_vm._e()],2)]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }