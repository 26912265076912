import Api from "@/Api/Api";
import SiteResponse from "@/Api/Site/Response/SiteResponse";
import SitesResponse from "@/Api/Site/Response/SitesResponse";
import SiteCreateRequest from "@/Api/Site/Request/SiteCreateRequest";
import PaginateObject from "@/Api/PaginateObject";

class SiteApi extends Api {
    list(serverId: string|null, paginate: PaginateObject|null = null): Promise<any> {
        let parameters: string = '';
        if (paginate !== null) {
            parameters = '?';
            // @ts-ignore
            parameters += new URLSearchParams(paginate).toString();
        }

        if (serverId !== null) {
            return this.get<SitesResponse>("/servers/" + serverId + "/sites" + parameters);
        }
        return this.get<SitesResponse>("/sites/" + parameters);
    }

    create(serverId: string, data: SiteCreateRequest): Promise<any> {
        return this.post<SiteCreateRequest>("/servers/" + serverId + "/sites", data);
    }

    deleteSite(siteId: string): Promise<any> {
        return this.delete("/sites/" + siteId);
    }

    getSite(siteId: string): Promise<any> {
        return this.get<SiteResponse>("/sites/" + siteId);
    }
}

export default new SiteApi();
