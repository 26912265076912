
























































import {Component, Vue, Watch} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";
import SitesResponse from "@/Api/Site/Response/SitesResponse";
import siteApi from "@/Api/Site/SiteApi";
import SiteResponse from "@/Api/Site/Response/SiteResponse";
import PaginateObject from "@/Api/PaginateObject";

@Component
export default class ServerIndex extends Vue {
    menuStore: MenuStore = getModule(MenuStore);
    sortBy: string = localStorage.getItem('sites-sort-by') ?? '';
    sortDesc: boolean = localStorage.getItem('sites-sort-desc') === "true";
    loading: boolean = true;

    totalItems: number = 0;
    options: any = {};
    headers: object = [
        {
            text: '',
            align: 'start',
            value: 'status',
            width: '20px'
        },
        {
            text: this.$t('site.siteName'),
            align: 'start',
            value: 'website',
        },
        {
            text: this.$t('createdAt'),
            value: 'created_at'
        }
    ];
    sites: SitesResponse = {
        data: [],
        links: {first: "", last: "", next: "", prev: ""},
        meta: {current_page: 0, from: 0, last_page: 0, links: [], per_page: 0, to: 0, total: 0}
    };

    mounted() {
        this.menuStore.addTitle(this.$t('site.sites') as string);
        this.getSites();
    }

    getSites() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const paginate: PaginateObject = {
            page: page,
            per_page: itemsPerPage,
            sort_by: sortBy,
            sort_desc: sortDesc,
        }
        siteApi.list(null, paginate).then((response) => {
            this.sites = response.data as SitesResponse;
            this.totalItems = this.sites.meta.total;
            this.loading = false;

            this.menuStore.addSubtitle(this.$tc('site.sitesCount', this.totalItems, {count: this.totalItems}) as string);
        });
    }

    @Watch('options')
    handler() {
        this.getSites();
    }

    sortByUpdate(type: string)
    {
        if (type === undefined) {
            localStorage.removeItem('sites-sort-by');
        } else {
            localStorage.setItem('sites-sort-by', type);
        }
    }

    sortDescUpdate(desc: boolean)
    {
        if (desc === undefined) {
            localStorage.removeItem('sites-sort-desc');
        } else {
            localStorage.setItem('sites-sort-desc', String(desc));
        }
    }

    handleClick(site: SiteResponse)
    {
        this.$router.push('/sites/' + site.id);
    }

    beforeDestroy() {
        this.menuStore.clear();
    }
}
